import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/:id',
    name: 'Confirm delivery',
    meta: { pageTitle: "Jan-art Confirm", title: 'Jan-art Confirm' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/DeliveryConfirmation.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
