require('dotenv').config();

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAMzEQXQc7SIFOQUwTIJffDGE6QBTwks8k",
  authDomain: "jan-art-orderconfirm.firebaseapp.com",
  projectId: "jan-art-orderconfirm",
  storageBucket: "jan-art-orderconfirm.appspot.com",
  messagingSenderId: "152639809986",
  appId: "1:152639809986:web:f32aba9ef57bea6338ce5b"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
  
//const db = firebase.firestore()
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const app = firebase.app()


export { firebase, auth, db, storage, app };