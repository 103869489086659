<template>
  <div id="app" class="w-screen">
    <router-view />
    <Transition name="fade">
      <VersionAlert @closeAlert="closeAlert()" class="absolute left-1/2 transform -translate-x-1/2 top-6 z-50" v-if="versionAlert.show && !versionAlert.deploy" />
    </Transition>
  </div>
  
</template>
<script>
  import VersionAlert from '@/components/VersionAlert.vue';
  import { XIcon as XMarkIcon } from '@heroicons/vue/solid';
  export default {
    data() {
      return {
        versionAlert: {
          show: true,
          deploy: true
        }
      }
    },
    watch: {
      // Set meta tags
      $route(to) {
        this.setMeta(to.meta);
      },
    },
    components: {
      VersionAlert, XMarkIcon
    },
    methods: {
      closeAlert() {
        this.versionAlert.show = false
      },
      setMeta(meta) {
        document.title = meta.pageTitle;
        if(meta.title === undefined)
        {
          document.title = 'Jan-Art System';
        }
      }
    },
    async created() {
      
      if(process.env.VUE_APP_VERSION === 'SANDBOX_LOCAL') {
        this.$store.state.apiLink = "http://127.0.0.1:5001/jan-art-vtest/europe-central2/api"
      } else if(process.env.VUE_APP_VERSION === 'SANDBOX') {
        this.$store.state.apiLink = "https://europe-central2-jan-art-vtest.cloudfunctions.net/api"
      } else if(process.env.VUE_APP_VERSION === 'PRODUCTION_LOCAL') {
        this.$store.state.apiLink = "http://127.0.0.1:5001/jan-art/europe-central2/api"
      } else if(process.env.VUE_APP_VERSION === 'PRODUCTION') {
        this.$store.state.apiLink = "https://europe-central2-jan-art.cloudfunctions.net/api"
      }
      console.log(process.env.VUE_APP_VERSION);
    },
  }
</script>
<style>

* {
    -webkit-tap-highlight-color: transparent;
}

body
{
  overflow-x: hidden;
}

.fade-enter-active,
.fade-leave-active {
transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
opacity: 0;
}

</style>

